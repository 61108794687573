body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#logo {
  width: 270px;
}

.full-height {
  height: 100vh !important;
  overflow: hidden !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

.cell-editor {
  width: 100%;
  height: 100%;
  outline: none;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input.MuiAutocomplete-inputFocused {
  font-size: 12px;
  height: 22px;
}

.table-premiums-summary tr td {
  padding: 5px;
}

.table-premiums-summary, .table-premiums-summary tr, .table-premiums-summary td {
  border: 1px solid #fff;
}

#table-my-health-indonesia-rates tr:not(:first-child) td {
  background-color: #fff;
}

#table-my-health-singapore-rates tr:not(:first-child) td {
  background-color: #fff;
}


#table-my-health-vietnam-rates tr:not(:first-child) td {
  background-color: #fff;
}

.MuiPaper-root.MuiPaper-outlined.MuiPaper-rounded.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters::before {
  height: 0px;
}

#select-insurer-card .MuiButtonBase-root.MuiCheckbox-root {
  padding-top: 4px;
  padding-bottom: 4px;
}